export const palette = {
  primary: {
    main: '#18a0fb',
    medium: '#74C6FD',
    light: '#D1ECFE',
  },
  common: {
    white: '#FFF',
    black: '#000',
  },
  secondary: {
    main: '#FDC564',
    medium: '#FFDFA2',
    light: '#FFF4E0',
    adventLoader: {
      yellow: '#FDD183',
      green: '#56BA82',
      red: '#EC4949',
    },
  },
  accent: {
    main: '#FA4969',
    red: '#EC4949',
  },
  background: {
    default: '#EFF7FF',
    contrast: '#031839',
    light: '#CEE2FF',
    primary: '#18a0fb',
    disabled: '#aaaaaa',
    lightest: '#FBFDFF',
    christmasGreen: '#12453F',
    christmasGreenLight: '#355046',
    christmasBlue: '#E5EBF5',
  },
  neutral: {
    dark: '#354661',
    medium: '#687488',
    medium2: '#9AA3B0',
    light: '#CDD1D7',
  },
  text: {
    default: '#031839',
    contrast: '#FFFFFF',
    smooth: '#EFF7FF',
    footerLink: '#D1ECFE',
  },
  chat: {
    bubble: {
      user: {
        background: '#FFFFFF',
        text: '#031839',
        border: '#FDC564',
      },
      scoopr: {
        background: '#FFFFFF',
        text: '#031839',
      },
      loading: {
        default: '#18a0fb',
      },
    },
    button: {
      default: {
        background: '#FDC564',
        text: '#031839',
      },
      transparent: {
        border: '#031839',
        text: '#031839',
      },
    },
    input: {
      border: '#18A0FB',
    },
  },
  success: {
    main: '#56BA82',
  },
  error: {
    main: '#FA4969',
  },
};

type CustomPalette = typeof palette;
type CustomBackground = CustomPalette['background'];
type CustomText = CustomPalette['text'];

declare module '@mui/material/styles' {
  // Fixes type errors, doesn't provide autocomplete, makes things unsafe
  // interface PaletteColor extends Record<string, any> {}

  interface TypeBackground extends CustomBackground {}
  interface TypeText extends CustomText {}
  interface Palette extends CustomPalette {}
}
