import './init'; // This must be at the top of the file

import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { initializeGTM } from './lib/analytics/gtm';
import { queryClient } from './queryClient';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setLevel('fatal');
        scope.setTag('app-crash', true);
      }}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

setTimeout(() => {
  initializeGTM();
}, 0);
