import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Container, Typography } from '@mui/material';
import React from 'react';

import { useGlobalAlertStore } from './_store';

// import { useGlobalAlertStore } from './_store';

// const content =
//   'Vi kan dessverre ikke motta søknader eller påmeldinger i dag mellom kl. 18:00-22:00 da Gjeldsregisteret er nede på grunn av vedlikehold.';

export type GlobalAlertProps = {
  // Empty
};

export const GlobalAlert: React.FC<GlobalAlertProps> = () => {
  const { content } = useGlobalAlertStore();

  if (!content) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.accent.red,
        width: '100%',
        py: { xs: 2, md: 3 },
        color: 'white',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: { xs: 1, sm: 2 },
        }}
      >
        <InfoOutlinedIcon
          sx={{
            mt: 0.5,
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
          }}
        />
        <Typography variant="h3n">{content}</Typography>
      </Container>
    </Box>
  );
};
