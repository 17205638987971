import { useMemo } from 'react';
import { useStore } from 'zustand';

import { store } from './_store';
import { decodeAccessToken } from './utils';

export const useAuthTokens = () => useStore(store, (state) => state.tokens);

export const useDecodedAccessToken = () => {
  const tokens = useAuthTokens();
  return useMemo(
    () =>
      tokens?.accessToken ? decodeAccessToken(tokens.accessToken) : undefined,
    [tokens?.accessToken],
  );
};
