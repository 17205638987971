import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect } from 'react';

import { useMe } from '@/hooks/useMe';
import { addGTMValue } from '@/lib/analytics/gtm';
import { sha256 } from '@/lib/sha256';
import {
  normalizeEmail,
  normalizePhoneNumberToE164,
  normalizePhoneNumberToE164NoPlus,
  removeDotsFromGmail,
} from '@/services/normalizers';

export type AnalyticsProps = {
  // Empty
};

export const Analytics: React.FC<AnalyticsProps> = () => {
  const { data: me } = useMe();

  const analytics_user_id = me?.analytics_user_id;
  const phone = me?.phone_number;
  const email = me?.email;
  const hashed_email = useSha256(email && normalizeEmail(email));
  const hashed_email_no_dots = useSha256(
    email && removeDotsFromGmail(normalizeEmail(email)),
  );
  const hashed_phone = useSha256(phone && normalizePhoneNumberToE164(phone));
  const hashed_phone_no_plus = useSha256(
    phone && normalizePhoneNumberToE164NoPlus(phone),
  );

  useEffect(() => {
    if (me) {
      Sentry.setUser({ id: me.id });
      Sentry.setTag('user.analytics_user_id', me.analytics_user_id);
    } else {
      Sentry.setUser(null);
      Sentry.setTag('user.analytics_user_id', undefined);
    }
  }, [me]);

  useEffect(() => {
    if (analytics_user_id) {
      addGTMValue({ event: 'user_identified', analytics_user_id });
    }
  }, [analytics_user_id]);

  useEffect(() => {
    if (analytics_user_id && hashed_email && hashed_email_no_dots) {
      addGTMValue({
        event: 'hashed_email_set',
        analytics_user_id,
        hashed_email,
        hashed_email_no_dots,
      });
    }
  }, [analytics_user_id, hashed_email, hashed_email_no_dots]);

  useEffect(() => {
    if (analytics_user_id && hashed_phone && hashed_phone_no_plus) {
      addGTMValue({
        event: 'hashed_phone_set',
        analytics_user_id,
        hashed_phone,
        hashed_phone_no_plus,
      });
    }
  }, [analytics_user_id, hashed_phone, hashed_phone_no_plus]);

  useEffect(() => {
    if (
      analytics_user_id &&
      hashed_phone &&
      hashed_phone_no_plus &&
      hashed_email &&
      hashed_email_no_dots
    ) {
      addGTMValue({
        event: 'hashed_email_and_phone_set',
        analytics_user_id,
        hashed_email,
        hashed_email_no_dots,
        hashed_phone,
        hashed_phone_no_plus,
      });
    }
  }, [
    analytics_user_id,
    hashed_email,
    hashed_email_no_dots,
    hashed_phone,
    hashed_phone_no_plus,
  ]);

  return null;
};

const useSha256 = (message: string | null | undefined) =>
  useQuery({
    queryKey: ['analytics-sha256', message],
    queryFn: () => sha256(message!),
    enabled: Boolean(message),
  }).data;
