import * as v from 'valibot';

/** Creates `nullish` schema and transforms any `null` values into `undefined` */
export const nullishAsOptional = <
  const TWrapped extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
>(
  wrapped: TWrapped,
) => v.nullish(wrapped, () => undefined);

export const paginated = <
  const TWrapped extends v.BaseSchema<unknown, unknown, v.BaseIssue<unknown>>,
>(
  wrapped: TWrapped,
) =>
  v.object({
    count: v.number(),
    next: nullishAsOptional(v.string()),
    previous: nullishAsOptional(v.string()),
    results: v.array(wrapped),
  });
