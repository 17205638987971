import * as v from 'valibot';

import { nullishAsOptional } from '@/lib/schema-utils';
import { DebtRegisterDataSchema } from '@/lib/schemas/debt-register';

const DebtAnalysisSchema = v.object({
  has_active_loans: v.boolean(),
  highest_loan_interest_rate: nullishAsOptional(v.number()),
  longest_loan_duration: nullishAsOptional(v.number()),
  percent_of_people_with_lower_rates: nullishAsOptional(v.number()),
});

export type DebtAnalysis = v.InferOutput<typeof DebtAnalysisSchema>;

const DebtSummarySchema = v.object({
  total_credit_facility_and_repayment_loan_amount: v.number(),
  total_loans_amount: v.number(),
});

export type DebtSummary = v.InferOutput<typeof DebtSummarySchema>;

const SavingsPotentialSchema = v.object({
  no_offer: v.boolean(),
  error: v.boolean(),
  interest_rate: v.number(),
  total_unsecured_debt: nullishAsOptional(v.number()),
  current_total_cost: nullishAsOptional(v.number()),
  saved_sum: nullishAsOptional(v.number()),
  loan_amount_tot: nullishAsOptional(v.number()),
  current_debt_duration: nullishAsOptional(v.number()),
  cost: nullishAsOptional(v.number()),
  saved_months: nullishAsOptional(v.number()),
});

export type SavingsPotential = v.InferOutput<typeof SavingsPotentialSchema>;

export const DebtRegisterSchema = v.object({
  debtAnalysis: nullishAsOptional(DebtAnalysisSchema),
  debtSummary: nullishAsOptional(DebtSummarySchema),
  debtData: nullishAsOptional(DebtRegisterDataSchema),
  debtOffer: nullishAsOptional(SavingsPotentialSchema),
  debtCommandId: v.string(),
  debtStrData: v.string(),
});

export type DebtRegister = v.InferOutput<typeof DebtRegisterSchema>;
