import type { RouteObject } from 'react-router-dom';

import routes from '@/assets/constants/routes-no';
import { smartLazy } from '@/lib/router-utils';

export const summerExtra2024Routes: RouteObject[] = [
  {
    path: routes.SUMMER_EXTRA_2024_COMPETITION,
    // loader: () =>
    //   simpleRace([
    //     queryClient.prefetchQuery(
    //       singleCompetitionWinnersQuery({ slug: 'summer_extra_2024_campaign' }),
    //     ),
    //   ]),
    lazy: () => smartLazy(import('./LandingPage')),
  },
  {
    path: routes.SUMMER_EXTRA_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
