import { SvgIcon } from '@mui/material';

const TiktokIcon = ({ ...props }) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
      fill="white"
    />
    <path
      d="M28.9375 16.875V20.3125C28.9375 20.4948 28.8651 20.6697 28.7361 20.7986C28.6072 20.9276 28.4323 21 28.25 21C26.8138 21.0033 25.3983 20.658 24.125 19.9937V23.4062C24.125 25.1384 23.4369 26.7997 22.212 28.0245C20.9872 29.2494 19.3259 29.9375 17.5938 29.9375C15.8616 29.9375 14.2003 29.2494 12.9755 28.0245C11.7506 26.7997 11.0625 25.1384 11.0625 23.4062C11.0625 20.2352 13.3751 17.4319 16.4422 16.8853C16.5411 16.8677 16.6427 16.872 16.7398 16.898C16.8369 16.9239 16.9272 16.9708 17.0042 17.0353C17.0812 17.0999 17.1431 17.1806 17.1856 17.2716C17.2281 17.3627 17.25 17.462 17.25 17.5625V21.2312C17.25 21.3613 17.2132 21.4887 17.1436 21.5987C17.0741 21.7087 16.9748 21.7967 16.8573 21.8525C16.5794 21.9843 16.3419 22.1882 16.1697 22.443C15.9975 22.6979 15.8968 22.9942 15.8782 23.3012C15.8595 23.6082 15.9236 23.9146 16.0637 24.1884C16.2038 24.4622 16.4148 24.6934 16.6746 24.8578C16.9345 25.0223 17.2338 25.114 17.5412 25.1234C17.8486 25.1328 18.1529 25.0596 18.4224 24.9113C18.6918 24.763 18.9166 24.5451 19.0731 24.2804C19.2297 24.0157 19.3124 23.7138 19.3125 23.4062V12.0625C19.3125 11.8802 19.3849 11.7053 19.5139 11.5764C19.6428 11.4474 19.8177 11.375 20 11.375H23.4375C23.6198 11.375 23.7947 11.4474 23.9236 11.5764C24.0526 11.7053 24.125 11.8802 24.125 12.0625C24.1261 13.1562 24.5611 14.2047 25.3344 14.9781C26.1078 15.7514 27.1563 16.1864 28.25 16.1875C28.4323 16.1875 28.6072 16.2599 28.7361 16.3889C28.8651 16.5178 28.9375 16.6927 28.9375 16.875Z"
      fill="#031839"
    />
  </SvgIcon>
);

export default TiktokIcon;
