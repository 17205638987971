import type { QueryClient, QueryKey } from '@tanstack/react-query';

export const createMutationFn = <TData, TVariables extends [any]>(config: {
  fn: (...args: TVariables) => Promise<TData>;
  invalidates: QueryKey[];
}) => {
  const fn = (...args: TVariables) => config.fn(...args);
  fn.invalidateCache = (queryClient: QueryClient) =>
    Promise.all(
      config.invalidates.map((queryKey) =>
        queryClient.invalidateQueries({ queryKey }),
      ),
    );

  return fn;
};
