import { createContext } from 'react';
import type { ReadyState, SendMessage } from 'react-use-websocket';

export type SocketContextValue = {
  /** @deprecated socket is initialized automatically */
  initWs: (id: string) => void;
  /** @deprecated */
  isWsInit: boolean;
  wsSendMessage: SendMessage;
  wsLastMessage: Record<string, any> | null;
  wsReadyState: ReadyState;

  // Computed fields
  wsStatus: string | undefined;
  wsComplete: boolean;
  wsError: boolean;
};

export const SocketContext = createContext<SocketContextValue>(undefined!);
