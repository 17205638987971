import { useQuery } from '@tanstack/react-query';

import { meQuery } from '@/lib/api/queries';
import { useAuth } from '@/services/Auth';

export const meQueryKey = meQuery().queryKey;

export const useMe = () => {
  const { isAnonymous } = useAuth();

  return useQuery({
    ...meQuery(),
    enabled: !isAnonymous,
  });
};
