import React from 'react';

import { BrainSessionContext } from './BrainSession';

export default function useBrainSession() {
  const context = React.useContext(BrainSessionContext);

  if (context === undefined) {
    throw new Error(
      'useBrainSession must be used within an BrainSessionProvider',
    );
  }

  return context;
}
