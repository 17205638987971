import type { RouteObject } from 'react-router-dom';

import routes from '@/assets/constants/routes-no';
import { singleCompetitionWinnersQuery } from '@/lib/api/queries';
import { simpleRace, smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const february2024Routes: RouteObject[] = [
  {
    path: routes.FEBRUARY_2024_COMPETITION,
    loader: () =>
      simpleRace([
        queryClient.prefetchQuery(
          singleCompetitionWinnersQuery({ slug: 'february_2024_campaign' }),
        ),
      ]),
    lazy: () => smartLazy(import('./LandingPage')),
  },
  {
    path: routes.FEBRUARY_2024_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
