import { Drawer, List } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { SIZES, useMenuItems } from '../../../../assets/constants/constants';
import { LANG } from '../../../../services/Config';
import MenuActionButton from '../MenuActionButton';

import NavItem from './_components/NavItem';

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  drawerPaper: {
    marginTop: SIZES.topBar.height.small,
    width: '100%',
  },
  drawer: {
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'none',
    },
  },
  drawerContent: {
    height: `calc(100vh - ${SIZES.topBar.height.small}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: spacing(3),
    overflowY: 'auto',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 8, 8),
    '& > a': {
      marginTop: spacing(2),
    },
    [breakpoints.down(450)]: {
      padding: spacing(3, 6, 8),
      '& > button, a': {
        paddingLeft: spacing(1.5),
        paddingRight: spacing(1.5),
      },
    },
  },
}));

const getAdditionalItems = (appStrings, routes) => [
  {
    title: appStrings.MENU.INTEREST_RATE,
    href: routes.INTEREST_RATE,
  },
  {
    title: appStrings.MENU.SMALL_LOAN,
    href: routes.SMALL_LOAN,
  },
  {
    title: appStrings.MENU.SAVINGS_POTENTIAL,
    href: routes.SAVINGS_POTENTIAL,
  },
];

const { appStrings, routes } = LANG;

const SideBar = ({ onSideBarClose, isSideBarOpen }) => {
  const { classes } = useStyles();

  const origItems = useMenuItems(appStrings, routes);
  const items = useMemo(
    () => [...origItems, ...getAdditionalItems(appStrings, routes)],
    [origItems],
  );

  return (
    <Drawer
      anchor="left"
      variant="temporary"
      classes={{ paper: classes.drawerPaper }}
      className={classes.drawer}
      onClose={onSideBarClose}
      open={isSideBarOpen}
      BackdropProps={{
        invisible: true,
      }}
    >
      <div className={classes.drawerContent}>
        <List component="nav">
          {items.map((item) => (
            <NavItem
              key={item.title}
              menuItem={item}
              onClick={() => {
                onSideBarClose();
              }}
            />
          ))}
        </List>

        <div className={classes.buttons}>
          <MenuActionButton
            sideBar
            onClick={() => {
              onSideBarClose();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

SideBar.propTypes = {
  onSideBarClose: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
  data: PropTypes.any,
};

SideBar.defaultProps = {
  onSideBarClose: () => {},
  isSideBarOpen: false,
  data: null,
};

export default SideBar;
