import { Tab } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { PLink } from '@/ui/PLink';

const useStyles = makeStyles()(({ breakpoints, spacing, typography }) => ({
  tabContainer: {
    margin: spacing(0, 1.5),
    [breakpoints.up(1100)]: {
      margin: spacing(0, 3.5),
    },
  },
  tabRoot: {
    minWidth: 0,
    ...typography.p3SemiBold,
    padding: spacing(3.75, 0, 2.75),
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

const TopBarItem = forwardRef(({ menuItem, ...props }, ref) => {
  const { classes } = useStyles();

  const { search } = useLocation();

  return (
    <div className={classes.tabContainer}>
      <Tab
        ref={ref}
        label={menuItem.title}
        component={PLink}
        to={`${menuItem.href}${search}`} // Passing params by search from landing page
        disableRipple
        classes={{
          root: classes.tabRoot,
        }}
        tabIndex={0}
        {...props}
      />
    </div>
  );
});

TopBarItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

TopBarItem.defaultProps = {};

export default TopBarItem;
