import { SvgIcon } from '@mui/material';

const MySavingsIcon = ({ ...props }) => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1162_6402)">
      <path
        d="M8.625 12H4.625C4.07272 12 3.625 12.4477 3.625 13V19C3.625 19.5523 4.07272 20 4.625 20H8.625C9.17728 20 9.625 19.5523 9.625 19V13C9.625 12.4477 9.17728 12 8.625 12Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 8H10.625C10.0727 8 9.625 8.44772 9.625 9V19C9.625 19.5523 10.0727 20 10.625 20H14.625C15.1773 20 15.625 19.5523 15.625 19V9C15.625 8.44772 15.1773 8 14.625 8Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.625 4H16.625C16.0727 4 15.625 4.44772 15.625 5V19C15.625 19.5523 16.0727 20 16.625 20H20.625C21.1773 20 21.625 19.5523 21.625 19V5C21.625 4.44772 21.1773 4 20.625 4Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.625 20H18.625"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1162_6402">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.625)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MySavingsIcon;
