import PropTypes from 'prop-types';

import useBrainSession from '../../../../services/BrainSession/useBrainSession';
import ActionButton from '../../../ActionButton';

const MenuActionButton = ({ className, onClick, sideBar, flow, step }) => {
  const { toggleChat } = useBrainSession();
  return (
    <ActionButton
      label="Søk om lavere rente"
      className={className}
      size="small"
      gaEvent="topCTAClick"
      color="primary"
      flow={flow}
      step={step}
      onClick={
        onClick && sideBar
          ? () => {
              onClick();
              toggleChat({
                goal: flow,
                step: step,
              });
            }
          : onClick
      }
    />
  );
};

MenuActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.any,
  sideBar: PropTypes.bool,
  flow: PropTypes.string,
  step: PropTypes.string,
};

MenuActionButton.defaultProps = {
  className: undefined,
  onClick: undefined,
  sideBar: false,
  flow: 'debt_check',
  step: 'skip_start',
};

export default MenuActionButton;
