import { SvgIcon } from '@mui/material';

const FacebookIcon = ({ ...props }) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
      fill="white"
    />
    <path
      d="M25.6062 22L26.2187 17.975H22.325V15.35C22.325 14.2125 22.85 13.1625 24.6 13.1625H26.3937V9.70625C26.3937 9.70625 24.775 9.4 23.2437 9.4C20.05 9.4 17.95 11.3688 17.95 14.8688V17.975H14.3625V22H17.95V31.8H22.325V22H25.6062Z"
      fill="#031839"
    />
  </SvgIcon>
);

export default FacebookIcon;
