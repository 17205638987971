import { queryOptions } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import * as v from 'valibot';

import { minutes } from '../utils';

import { apiClient } from './apiClient';

import { addGTMValue } from '@/lib/analytics/gtm';
import { getRegisterSourceData } from '@/lib/analytics/registerSource';
import { getVertex } from '@/lib/analytics/vertex';
import { nullishAsOptional, paginated } from '@/lib/schema-utils';
import { updateRegisterSource } from '@/services/Http/mutation-fns';

const CompetitionWinnerSchema = v.object({
  sex: v.string(),
  age: v.string(),
  city: v.string(),
  amount: v.string(),
});

export type CompetitionWinner = v.InferOutput<typeof CompetitionWinnerSchema>;

const CompetitionSchema = v.object({
  slug: v.string(),
  title: v.string(),
  subtitle: v.string(),
  month: v.string(),
  winners: v.array(CompetitionWinnerSchema),
});

export type Competition = v.InferOutput<typeof CompetitionSchema>;

export const competitionWinnersQuery = () =>
  queryOptions({
    queryKey: ['competition-winners'],
    queryFn: async () => {
      const res = await apiClient
        .get(`events_management/events/march_2024_campaign/`)
        .json();

      const schema = v.object({
        configuration: v.array(CompetitionSchema),
      });

      return v.parse(schema, res).configuration;
    },
    meta: {
      reportToSentry: true,
    },
    staleTime: minutes(5),
  });

export type SingleCompetitionWinnersQueryArgs = {
  slug: string;
};

export const singleCompetitionWinnersQuery = ({
  slug,
}: SingleCompetitionWinnersQueryArgs) =>
  queryOptions({
    ...competitionWinnersQuery(),
    select: (data) => data.find((c) => c.slug === slug),
  });

export const getDecember2023ConfigQuery = () =>
  queryOptions({
    queryKey: ['december-2023-config'],
    queryFn: async () => {
      const res = await apiClient
        .get('events_management/events/advent_of_loans_2023/')
        .json<{ configuration: any }>();

      return res.configuration;
    },
    staleTime: minutes(60),
  });

export const authInitQuery = () =>
  queryOptions({
    queryKey: ['auth', 'init'],
    queryFn: async () => {
      const registerSource = getRegisterSourceData();

      const [resRaw] = await Promise.all([
        apiClient
          .post('brain/api/legion/', {
            json: {
              vertex: getVertex(null),
              content_type: 'init',
            },
          })
          .json(),
        updateRegisterSource(registerSource),
      ]);

      const schema = v.object({
        vertex: v.object({
          id: v.object({
            id: v.string(),
            uid: v.string(),
          }),
          user_id: nullishAsOptional(v.string()),
        }),
      });

      const res = v.parse(schema, resRaw);

      addGTMValue({ landing_page: registerSource.url_path || '/' });

      return { userId: res.vertex.user_id };
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });

export const MeSchema = v.object({
  id: v.string(),
  created_at: v.string(),
  email: nullishAsOptional(v.string()),
  phone_number: nullishAsOptional(v.string()),
  suggested_email: nullishAsOptional(v.string()),
  analytics_user_id: v.string(),
});

export type Me = v.InferOutput<typeof MeSchema>;

export const meQuery = () =>
  queryOptions({
    queryKey: ['me'],
    queryFn: async () => {
      const res = await apiClient.get('account/me/').json();
      return v.parse(MeSchema, res);
    },
  });

export type CheckManyConsentsResponse = {
  all_check: boolean;
  details: Record<string, boolean>;
};

export const checkManyConsentsQuery = <T extends string>(consents: T[]) =>
  queryOptions({
    queryKey: ['consents', 'check-many', { consents }],
    queryFn: async (): Promise<CheckManyConsentsResponse> => {
      const res = await apiClient
        .post('bi/consent-clusters/all_consents_check/', {
          json: { slugs: consents },
        })
        .json();

      const schema = v.record(v.picklist(consents), v.boolean());
      const data = v.parse(schema, res);

      return {
        all_check: Object.values(data).every((x) => x),
        details: data,
      };
    },
  });

const ConsentSchema = v.object({
  id: v.string(),
  created: v.string(),
  revoked: nullishAsOptional(v.string()),
  source: v.string(),
});

export type Consent = v.InferOutput<typeof ConsentSchema>;

const ConsentKindSchema = v.object({
  id: v.string(),
  slug: v.string(),
  name: v.string(),
  text_selector: v.string(),
  text: nullishAsOptional(v.string()),
  consents: v.array(ConsentSchema),
  created: v.string(),
});

export type ConsentKind = v.InferOutput<typeof ConsentKindSchema>;

export const getConsentsQuery = () =>
  queryOptions({
    queryKey: ['consents', 'all'],
    queryFn: async () => {
      const res = await apiClient
        .get('bi/consent/', { searchParams: { per_page: 50 } })
        .json();
      const schema = paginated(ConsentKindSchema);
      return v.parse(schema, res);
    },
  });

export type GetExperimentQueryArgs = {
  id: string;
};

const ExperimentSchema = v.object({
  cookie: v.string(),
  cookie_name: v.string(),
  experiment_id: v.string(),
  index: v.number(),
});

// Eating raw cookies is not healthy, but sometimes necessary
const rawCookies = Cookies.withConverter({
  read: (value) => value,
  write: (value) => value,
});

export type Experiment = v.InferOutput<typeof ExperimentSchema>;

export const getExperimentQuery = ({ id }: GetExperimentQueryArgs) => {
  return queryOptions({
    queryKey: ['experiments', id],
    queryFn: async () => {
      const res = await apiClient
        .get(`optimize/experiments/${id}/get_variant/`)
        .json();

      const data = v.parse(ExperimentSchema, res);

      rawCookies.set(data.cookie_name, data.cookie, {
        domain: '.scoopr.no',
        secure: true,
        sameSite: 'Strict',
        expires: 30,
      });

      return data;
    },
  });
};
