import { Typography as MuiTypography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  customTypography,
  newTypography,
} from '../../assets/styles/typography';

/** @deprecated */
const Typography = ({ variant, children, component, className, ...props }) => {
  const isCustom = Object.keys(customTypography).indexOf(variant) > -1;

  return (
    <MuiTypography
      variant={variant}
      component={component || (isCustom ? 'p' : undefined)}
      className={className}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'subtitle1',
    'subtitle2',
    'inherit',
    ...Object.keys(customTypography),
    ...Object.keys(newTypography),
  ]),
  component: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

Typography.defaultProps = {
  variant: 'inherit',
  component: undefined,
  className: '',
  children: null,
};

export default Typography;
