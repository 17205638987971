import * as Sentry from '@sentry/react';
import { addSeconds } from 'date-fns';
import Cookies from 'js-cookie';

export const handleLazyLoadingErrors = () => {
  const key = 'PRELOAD-RELOAD';

  window.addEventListener('vite:preloadError', (event) => {
    if (Cookies.get(key)) {
      Sentry.setTag('preload-error', true);
    } else {
      Cookies.set(key, 'true', { expires: addSeconds(new Date(), 30) });
      event.preventDefault();
      window.location.reload();
    }
  });
};
