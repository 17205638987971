export class FetchError extends Error {
  name = 'FetchError';
}

// Convenient wrapper around fetch, makes it easy to know when network errors occur
export const fetchWithError = async (
  input: string | URL | Request,
  init?: RequestInit,
) => {
  try {
    return await fetch(input, init);
  } catch (e) {
    throw new FetchError('Fetch error', { cause: e });
  }
};
