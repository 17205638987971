// Safari 12.0
if (!Object.fromEntries) {
  Object.defineProperty(Object, 'fromEntries', {
    value(entries: Iterable<readonly [PropertyKey, any]>) {
      const result: Record<PropertyKey, any> = {};

      for (const [key, value] of entries) {
        result[key] = value;
      }

      return result;
    },
  });
}
