import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { useSocketIO } from 'react-use-websocket';

import { API } from '../Config';
import { useDecodedAccessToken } from '../tokens/useAuthTokens';

import { SocketContext } from './SocketContext';

import { getWsStatus } from '@/lib/utils';

export type SocketProviderProps = { children?: ReactNode };

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [isWsInit, setIsWsInit] = useState(false);
  const lastIdRef = useRef<string>();
  const {
    sendMessage: wsSendMessage,
    lastMessage: wsLastMessage,
    readyState: wsReadyState,
  } = useSocketIO(API.ws);

  const initWs = useCallback(
    (id: string) => {
      if (id !== lastIdRef.current) {
        wsSendMessage(`42["init",{"foreignId":"${id}"}]`);
        setIsWsInit(true);
        lastIdRef.current = id;
      }
    },
    [wsSendMessage],
  );

  const decodedToken = useDecodedAccessToken();

  // Auto init
  useEffect(() => {
    if (wsReadyState === 1 && decodedToken?.brain_session_id) {
      initWs(decodedToken.brain_session_id);
    }
  }, [wsReadyState, decodedToken?.brain_session_id, initWs]);

  const { wsStatus, wsComplete, wsError } = getWsStatus(wsLastMessage);

  return (
    <SocketContext.Provider
      value={{
        isWsInit,
        wsSendMessage,
        wsLastMessage,
        wsReadyState,
        initWs,
        wsStatus,
        wsComplete,
        wsError,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
