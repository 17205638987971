import * as v from 'valibot';

import { nullishAsOptional } from '@/lib/schema-utils';

const CSPaymentRemarksSchema = v.object({
  unsettledROPNumber: v.number(),
  unsettledROPAmount: v.number(),
  partlySettledROPNumber: v.number(),
  partlySettledROPAmount: v.number(),
  voluntaryPledgeNumber: v.number(),
  voluntaryPledgeAmount: v.number(),
});

export type CSPaymentRemarks = v.InferOutput<typeof CSPaymentRemarksSchema>;

const CSBasicInformationSchema = v.object({
  fullName: v.string(),
  firstName: v.string(),
  middleName: nullishAsOptional(v.string()),
  lastName: v.string(),
  personalNumber: v.string(),
  age: v.number(),
  birthDate: v.string(),
  gender: v.string(),
  statusCode: v.number(),
  statusText: v.string(),
  address: v.object({
    address: nullishAsOptional(v.string()),
    city: nullishAsOptional(v.string()),
    municipalityCode: nullishAsOptional(v.string()),
    municipalityName: nullishAsOptional(v.string()),
    countyCode: nullishAsOptional(v.string()),
    countyName: nullishAsOptional(v.string()),
    postCode: nullishAsOptional(v.string()),
  }),
  noRegisteredProperties: nullishAsOptional(v.number()),
  noHousingShareProperties: nullishAsOptional(v.number()),
  noCompanyEngagements: nullishAsOptional(v.number()),
  noVoluntaryPledges: nullishAsOptional(v.number()),
  noPaymentRemarks: nullishAsOptional(v.number()),
});

export type CSBasicInformation = v.InferOutput<typeof CSBasicInformationSchema>;

const CSIncomeSchema = v.object({
  incomeYear: v.number(),
  taxClass: nullishAsOptional(v.string()),
  netWealth: nullishAsOptional(v.number()),
  netIncome: nullishAsOptional(v.number()),
  sumTax: nullishAsOptional(v.number()),
  grossIncome: nullishAsOptional(v.number()),
  municipalityCode: nullishAsOptional(v.string()),
});

export type CSIncome = v.InferOutput<typeof CSIncomeSchema>;

const CSPropertyInformationSchema = v.object({
  name: v.string(),
  cadastreKey: v.string(),
  address: v.string(),
  owners: v.array(v.string()),
  registeredDate: v.string(),
  turnoverPrice: v.number(),
  turnoverType: nullishAsOptional(v.string()),
});

export type CSPropertyInformation = v.InferOutput<
  typeof CSPropertyInformationSchema
>;

const CSPropertySchema = v.object({
  registeredProperties: nullishAsOptional(v.array(CSPropertyInformationSchema)),
  housingShareProperties: nullishAsOptional(
    v.array(CSPropertyInformationSchema),
  ),
});

export type CSProperty = v.InferOutput<typeof CSPropertySchema>;

const CSCompanyEngagementSchema = v.object({
  companyNumber: v.string(),
  companyName: v.string(),
  function: v.string(),
  appointmentDate: nullishAsOptional(v.string()),
  companyStatusCode: nullishAsOptional(v.string()),
  companyStatusText: nullishAsOptional(v.string()),
  companyRegistrationDate: v.string(),
  creditLimit: nullishAsOptional(v.number()),
  creditRating: nullishAsOptional(v.number()),
});

export type CSCompanyEngagement = v.InferOutput<
  typeof CSCompanyEngagementSchema
>;

const CSPaymentRemarkDetailItemSchema = v.object({
  type: v.string(),
  date: v.string(),
  source: v.string(),
  refNr: nullishAsOptional(v.string()),
  amount: v.number(),
  creditor: nullishAsOptional(v.string()),
  status: nullishAsOptional(v.string()),
  periodStart: nullishAsOptional(v.string()),
  periodEnd: nullishAsOptional(v.string()),
});

export type CSPaymentRemarkDetailItem = v.InferOutput<
  typeof CSPaymentRemarkDetailItemSchema
>;

const CSPaymentRemarkDetailsSchema = v.object({
  voluntaryPledges: v.array(CSPaymentRemarkDetailItemSchema),
  remarksOfPayment: v.array(CSPaymentRemarkDetailItemSchema),
});

export type CSPaymentRemarkDetails = v.InferOutput<
  typeof CSPaymentRemarkDetailsSchema
>;

const CSCreditScoreSchema = v.object({
  commonScoreBand: v.picklist(['A', 'B', 'C', 'D', 'E']),
  decision: v.string(),
  score: v.number(),
});

export type CSCreditScore = v.InferOutput<typeof CSCreditScoreSchema>;

const CSScoreSchema = v.object({
  currentCreditScore: CSCreditScoreSchema,
});

export type CSScore = v.InferOutput<typeof CSScoreSchema>;

const CreditScoreSchema = v.object({
  BasicInformation: nullishAsOptional(CSBasicInformationSchema),
  Incomes: nullishAsOptional(v.array(CSIncomeSchema)),
  Property: nullishAsOptional(CSPropertySchema),
  CompanyEngagements: nullishAsOptional(v.array(CSCompanyEngagementSchema)),
  PaymentRemarks: nullishAsOptional(CSPaymentRemarksSchema),
  PaymentRemarkDetails: nullishAsOptional(CSPaymentRemarkDetailsSchema),
  Score: nullishAsOptional(CSScoreSchema),
  DebtRegister: nullishAsOptional(v.string()),
});

export type CreditScoreData = v.InferOutput<typeof CreditScoreSchema>;

export const CreditCheckSchema = v.object({
  scoreData: CreditScoreSchema,
  scoreStrData: v.string(),
  scoreCommandId: v.string(),
});

export type CreditCheckData = v.InferOutput<typeof CreditCheckSchema>;
