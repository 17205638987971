import './polyfills';

import * as Sentry from '@sentry/react';

import { handleLazyLoadingErrors } from '@/lib/handleLazyLoadingErrors';
import { APP_VERSION, COMMIT_ID } from '@/lib/version';
import { initTokens } from '@/services/tokens/actions';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://2d9c93c600ab49289544bc6087f8e00a@o572739.ingest.sentry.io/5722440',
    release: `${import.meta.env.VITE_NAME}@${APP_VERSION}+${COMMIT_ID}`,
    environment: import.meta.env.VITE_STAGE,
    allowUrls: [/https?:\/\/.*scoopr.no/],
    ignoreErrors: [
      /**
       * React internal error thrown when something outside react modifies the DOM
       * This is usually because of a browser extension or Chrome's built-in translate
       */
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      // Web vitals
      "TypeError: Cannot read properties of undefined (reading 'domInteractive')",
      "TypeError: undefined is not an object (evaluating 'this._perf.domInteractive')",
      // Tiktok analytics
      "Can't find variable: ttq",
      'ttq is not defined',
    ],
    beforeSend: (event, hint) => {
      const stack = hint.syntheticException?.stack;

      if (
        stack?.includes('chrome-extension://') ||
        stack?.includes('i18n/pixel/static/main') ||
        stack?.includes('gtag/js')
      ) {
        return null;
      }

      return event;
    },
  });
}

handleLazyLoadingErrors();
initTokens();

console.log(`Portal version: ${APP_VERSION} (${COMMIT_ID})`);
