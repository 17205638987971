import { createJSONStorage, persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

import type { Tokens } from './types';

export type TokenStore = {
  tokens: Tokens | undefined;
  setTokens: (tokens: Tokens) => void;
  removeTokens: () => void;
};

export const store = createStore<TokenStore>()(
  persist(
    (set) => ({
      tokens: undefined,
      setTokens: (tokens) => {
        // Sometimes just typescript is not enough :)
        if (tokens?.accessToken && tokens?.refreshToken) {
          set({ tokens });
        } else {
          throw new Error('Cannot save tokens, invalid format');
        }
      },
      removeTokens: () => set({ tokens: undefined }),
    }),
    {
      name: 'SCOOPR_TOKEN',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
