import { createContext } from 'react';

import { addGTMValue, addLegacyGTMEvent } from '@/lib/analytics/gtm';

/** @deprecated use `@/lib/analytics/gtm` module instead */
export const TrackingContext = createContext({
  /** @deprecated use `addLegacyGTMEvent` instead */
  addEvent: addLegacyGTMEvent,
  /** @deprecated use `addGTMValue` instead */
  addValue: addGTMValue,
});
