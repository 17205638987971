import { SvgIcon } from '@mui/material';

const MyScoreIcon = ({ ...props }) => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1162_6408)">
      <path
        d="M12.125 15C13.2296 15 14.125 14.1046 14.125 13C14.125 11.8954 13.2296 11 12.125 11C11.0204 11 10.125 11.8954 10.125 13C10.125 14.1046 11.0204 15 12.125 15Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5752 11.55L15.6252 9.5"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.525 19.9997C5.06315 18.8377 3.99891 17.2499 3.47978 15.4561C2.96064 13.6624 3.01232 11.7516 3.62765 9.98847C4.24297 8.2254 5.39147 6.69737 6.91399 5.61616C8.4365 4.53495 10.2576 3.9541 12.125 3.9541C13.9924 3.9541 15.8135 4.53495 17.336 5.61616C18.8585 6.69737 20.007 8.2254 20.6224 9.98847C21.2377 11.7516 21.2894 13.6624 20.7702 15.4561C20.2511 17.2499 19.1869 18.8377 17.725 19.9997H6.525Z"
        stroke="#9AA3B0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1162_6408">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.125)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default MyScoreIcon;
