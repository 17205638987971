import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import type { GlobalEventMap } from './global-events';
import type { EVENTS } from './legacyEvents';

import type { Advent2024EventMap } from '@/competitions/2024/advent/config';
import type { SummerExtra2024EventMap } from '@/competitions/2024/summer-extra/config';
import type { WakeUp2024EventMap } from '@/competitions/2024/wake-up/config';
import { GTM } from '@/services/Config';

const GTM_DEBUG_KEY = 'SCOOPR_GTM_DEBUG_ENABLED';

let isGTMDebugEnabled = localStorage.getItem(GTM_DEBUG_KEY) === 'true';

const toggleGTMDebug = () => {
  isGTMDebugEnabled = !isGTMDebugEnabled;
  localStorage.setItem(GTM_DEBUG_KEY, isGTMDebugEnabled.toString());
  console.log(
    `GTM debugging is now ${isGTMDebugEnabled ? 'enabled' : 'disabled'}`,
  );
};

(window as any).__sc ??= {};
(window as any).__sc.toggleGTMDebug = toggleGTMDebug;

const envs = {
  dev: {
    auth: 'Qt1PyqNroNqhIHL5HuzlxQ',
    preview: 'env-7',
  },
  prod: {
    auth: 'xD4PUOPlW6TgqTuA3fxYOg',
    preview: 'env-2',
  },
  alpha: {
    auth: 'SmCkqVYrYDiyX39pSOE11g',
    preview: 'env-33',
  },
  poc: {},
};

const envVariable: Record<string, any> = {
  localhost: { ...envs.dev },
  'hudya.no': { ...envs.prod },
  'scoopr.no': { ...envs.prod },
  'dev.scoopr.no': { ...envs.dev },
  'alpha.scoopr.no': { ...envs.poc },
  'sandbox.scoopr.no': { ...envs.poc },
};

export const initializeGTM = () => {
  TagManager.initialize({
    gtmId: GTM.id,
    ...envVariable[window.location.hostname],
  });
};

const getOptimizeCookie = () => {
  const optimizeCookie = Cookies.get('SCOOPR_OPTIMIZE_EXP');
  if (!optimizeCookie) {
    return {};
  }

  const relevantPart = optimizeCookie.substring(2);
  const keyValuePairs = relevantPart.split(',');

  const result = Object.fromEntries(
    keyValuePairs.map((pair) => pair.split('.')),
  );

  return {
    experiments_data: JSON.stringify(result),
  };
};

const addValue = (value: object, parameters?: object | void) => {
  const experimentsData = getOptimizeCookie();

  const dataLayerObj = {
    ...value,
    ...experimentsData,
    ...parameters,
  };

  if (isGTMDebugEnabled) {
    const logStyles = `
      color: white;
      background-color: #3498db;
      padding: 2px 5px;
      border-radius: 3px;
      font-weight: bold;
    `;
    if ('event' in value) {
      console.groupCollapsed('%cGTM Event', logStyles, value.event);
      console.log(dataLayerObj);
      console.groupEnd();
    } else {
      console.log('%cGTM Value', logStyles, dataLayerObj);
    }
  }

  TagManager.dataLayer({
    dataLayer: dataLayerObj,
  });
};

export const addGTMValue = (value: object) => addValue(value);

type EventMap = GlobalEventMap &
  SummerExtra2024EventMap &
  WakeUp2024EventMap &
  Advent2024EventMap;

export const addGTMEvent = <Event extends keyof EventMap>(
  event: Event,
  ...args: EventMap[Event] extends void ? [] : [parameters: EventMap[Event]]
) => addValue({ category: 'tracking', event, action: event }, args[0]);

export const getGaClientId = () => {
  const ga = (window as any).ga;

  if (!ga) {
    return '';
  }

  let clientId = '';
  ga((tracker: any) => {
    clientId = tracker.get('clientId');
  });
  return clientId;
};

const getLegacyEventConfig = async (action: keyof typeof EVENTS) => {
  const { EVENTS } = await import('./legacyEvents');

  const config = EVENTS[action];

  if (config) {
    return config;
  } else {
    const fallback = {
      action: action,
      category: 'tracking',
      event: action,
    };
    Sentry.captureMessage(`Invalid analytics event ${action}`, {
      extra: { action, fallback },
    });
    console.warn(
      `WARNING: Event '${action}' is not a valid event! It needs to be properly configured.`,
      'Sending fallback configuration instead:',
      fallback,
    );
    return fallback;
  }
};

export const addLegacyGTMEvent = async (
  action: keyof typeof EVENTS,
  parameters?: object,
) => {
  const config = await getLegacyEventConfig(action);
  addValue(config, parameters);
};
