import { differenceInMinutes } from 'date-fns';
import Cookies from 'js-cookie';

import { API } from '../Config';

import type { Tokens } from './types';
import {
  decodeAccessToken,
  getTokens,
  isTokenExpired,
  removeTokens,
  saveTokens,
} from './utils';

let operation: Promise<Tokens> | undefined = undefined;

let lastRefreshes: Date[] = [];

const dedupe = <TArgs extends unknown[]>(
  fn: (...args: TArgs) => Promise<Tokens>,
): ((...args: TArgs) => Promise<Tokens>) => {
  return (...args) => {
    if (!operation) {
      operation = fn(...args).finally(() => (operation = undefined));
    }
    return operation;
  };
};

export const refreshTokens = dedupe(async (): Promise<Tokens> => {
  const tokens = getTokens();

  if (!tokens) {
    throw new Error('Cannot refresh tokens without existing tokens');
  }

  const now = new Date();
  const refreshes = lastRefreshes.filter(
    (x) => differenceInMinutes(now, x) <= 1,
  );

  if (refreshes.length > 2) {
    throw new Error('Failed to refresh tokens, too many attempts');
  }

  lastRefreshes = [...refreshes, now];

  try {
    // Raw `fetch` call to avoid interference with interceptors
    const res = await fetch(`${API.url}/authorization/session/token/refresh/`, {
      method: 'POST',
      body: JSON.stringify({ refresh: tokens.refreshToken }),
      headers: { 'content-type': 'application/json' },
    });

    if (!res.ok) {
      throw new Error(
        `Request failed with status ${res.status} ${res.statusText}`,
      );
    }

    const data = await res.json();

    const newTokens = {
      accessToken: data.access,
      refreshToken: data.refresh,
    };

    saveTokens(newTokens);

    return newTokens;
  } catch (error) {
    throw new Error('Failed to refresh tokens', { cause: error });
  }
});

export const obtainNewTokens = dedupe(async (sid?: string): Promise<Tokens> => {
  try {
    const body = sid ? JSON.stringify({ brain_session_id: sid }) : undefined;

    // Raw `fetch` call to avoid interference with interceptors
    const res = await fetch(`${API.url}/authorization/session/token/`, {
      method: 'POST',
      body,
      headers: body ? { 'content-type': 'application/json' } : {},
    });

    if (!res.ok) {
      throw new Error(
        `Request failed with status ${res.status} ${res.statusText}`,
      );
    }

    const data = await res.json();

    const newTokens = {
      accessToken: data.access,
      refreshToken: data.refresh,
    };

    saveTokens(newTokens);

    return newTokens;
  } catch (error) {
    throw new Error('Failed to obtain new tokens', { cause: error });
  }
});

/**
 * Returns a promise which represents currently running token operation
 * like refresh or obtain.
 * Promise will resolve immediately if there's no operations running.
 *
 * Can be used to hold requests until new tokens are provided.
 */
export const getCurrentTokensOperation = (): Promise<void> | undefined => {
  return operation && operation.then(() => {}).catch(() => {}); // External observers don't care about values or errors
};

/**
 * Executes tokens initialization logic.
 * This function should be called only once, before any requests are made.
 */
export const initTokens = () => {
  const getTokenFromCookies = (): Tokens | undefined => {
    const accessToken = Cookies.get('SCOOPR_JWT_TOKEN_ACCESS');
    const refreshToken = Cookies.get('SCOOPR_JWT_TOKEN_REFRESH');

    const domain = `.${window.location.host}`;

    Cookies.remove('SCOOPR_JWT_TOKEN_ACCESS', { domain });
    Cookies.remove('SCOOPR_JWT_TOKEN_REFRESH', { domain });

    if (accessToken && refreshToken) {
      return { accessToken, refreshToken };
    }
  };

  const getNextTokens = () => {
    const tokensFromCookies = getTokenFromCookies();

    if (tokensFromCookies) {
      return tokensFromCookies;
    }

    const sid = new URLSearchParams(window.location.search).get('sid');

    if (sid) {
      obtainNewTokens(sid);
      return undefined;
    }

    return getTokens();
  };

  const nextTokens = getNextTokens();

  if (nextTokens && !isTokenExpired(nextTokens.refreshToken)) {
    saveTokens(nextTokens);
    if (isTokenExpired(nextTokens.accessToken)) {
      refreshTokens();
    }
  } else {
    removeTokens();
    obtainNewTokens();
  }
};

export const getIsAnonymous = async () => {
  await getCurrentTokensOperation();
  const tokens = getTokens();
  return !tokens || decodeAccessToken(tokens.accessToken).is_anonymous;
};

export const canAccessAgentPortal = async () => {
  await getCurrentTokensOperation();
  const tokens = getTokens();
  return (
    tokens &&
    decodeAccessToken(tokens.accessToken).scopes.includes(
      'agent_portal.can_view_agent_portal',
    )
  );
};
