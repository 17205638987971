import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import LogoIcon from '../../assets/icons/LogoIcon';

const useStyles = makeStyles()(({ breakpoints }) => ({
  logoRoot: {
    width: 'auto',
    height: 18,
    [breakpoints.up('md')]: {
      height: 25,
    },
  },
}));

const Logo = ({ className, ...rest }) => {
  const { classes, cx } = useStyles();
  return <LogoIcon className={cx(classes.logoRoot, className)} {...rest} />;
};

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
