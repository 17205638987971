import { Error as ErrorIcon } from '@mui/icons-material';
import { Box, Container, Paper, styled, Typography } from '@mui/material';
import type React from 'react';
import { useRouteError } from 'react-router-dom';
import * as v from 'valibot';

const StyledPre = styled('pre')(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontFamily: 'monospace',
  backgroundColor: theme.palette.background.contrast,
  borderRadius: 4,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  margin: 0,
  lineHeight: 1.4,
  fontSize: '0.8125rem',
}));

export const AgentErrorBoundary = () => {
  const error = useRouteError() as any;

  return (
    <Container maxWidth="md" sx={{ my: 10 }}>
      <Paper
        sx={{
          color: 'inherit',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Box display="flex" alignItems="center" gap={2} mt={-0.5}>
          <ErrorIcon sx={{ color: 'error.main', fontSize: '2rem' }} />
          <Typography variant="h2n" component="h1">
            An error occurred
          </Typography>
        </Box>

        <ErrorDetails error={error} />
      </Paper>
    </Container>
  );
};

type ErrorDetailsProps = {
  error: Error;
};

const ErrorDetails: React.FC<ErrorDetailsProps> = ({ error }) => {
  const getMessage = () => {
    return error?.message || 'Unknown error';
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <div>
        <Typography variant="h5n" component="h3" mb={0.5}>
          Message:
        </Typography>
        <Typography variant="body4n" component="h2">
          {getMessage()}
        </Typography>
      </div>

      {error instanceof v.ValiError && (
        <div>
          <Typography variant="h5n" component="h3" mb={0.5}>
            Validation errors:
          </Typography>
          <StyledPre>
            {JSON.stringify(v.flatten(error.issues), null, 2)}
          </StyledPre>
        </div>
      )}

      {error?.stack && (
        <div>
          <Typography variant="h5n" component="h3" mb={0.5}>
            Stack trace:
          </Typography>
          <StyledPre>{error?.stack}</StyledPre>
        </div>
      )}

      {error?.cause ? (
        <div>
          <Typography variant="h4n" component="h3" mb={1}>
            Caused by:
          </Typography>
          <Box
            sx={{
              borderLeft: '2px solid',
              borderColor: (theme) => theme.palette.background.contrast,
              pl: 2,
            }}
          >
            <ErrorDetails error={error?.cause as Error} />
          </Box>
        </div>
      ) : null}
    </Box>
  );
};
