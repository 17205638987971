import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { HTTPError } from 'ky';
import * as v from 'valibot';

import { contextsFromSchemaError } from './lib/utils';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      staleTime: 10 * 1000,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.error('Error while executing query', query.queryKey, error);

      if (query.meta?.reportToSentry) {
        // Only capture requests that made it to the server (and back)
        if (error instanceof HTTPError || error instanceof v.ValiError) {
          Sentry.captureException(
            new Error('Error while executing query: ' + query.queryHash, {
              cause: error,
            }),
            {
              contexts: {
                Query: {
                  key: query.queryHash,
                  error: (error as Error).message,
                },
                ...(error instanceof v.ValiError &&
                  contextsFromSchemaError(error)),
              },
            },
          );
        }
      }
    },
  }),
});
