import * as v from 'valibot';

import { nullishAsOptional } from '@/lib/schema-utils';

const PropertyValueSchema = v.object({
  cadastre_key: nullishAsOptional(v.string()),
  identifier: nullishAsOptional(v.string()),
  estate_type: nullishAsOptional(v.string()),
  address: nullishAsOptional(
    v.object({
      id: nullishAsOptional(v.string()),
      post_office_code: nullishAsOptional(v.string()),
      post_office_name: nullishAsOptional(v.string()),
      street_name: v.string(),
      municipality: v.string(),
      street_letter: nullishAsOptional(v.string()),
      street_number: nullishAsOptional(v.string()),
    }),
  ),
  market_estimate: nullishAsOptional(
    v.object({
      estimate: v.number(),
      estimate_high: v.number(),
      estimate_low: v.number(),
      created_at: nullishAsOptional(v.string()),
    }),
  ),
  housing_cooperative_share_identity: nullishAsOptional(
    v.object({
      organization_number: v.string(),
      share_number: v.string(),
    }),
  ),
});

export type PropertyValue = v.InferOutput<typeof PropertyValueSchema>;

export const PropertiesSchema = v.object({
  propertyValues: v.array(PropertyValueSchema),
});

export type PropertiesData = v.InferOutput<typeof PropertiesSchema>;
