import Cookies from 'js-cookie';

import { getGaClientId } from './gtm';
import { getRegisterSourceData } from './registerSource';

import { GTM } from '@/services/Config';

export const getVertex = (clientKnowledge: unknown) => {
  const rParams = getRegisterSourceData();
  const getCookie = (name: string) => {
    return Cookies.get(name) || '';
  };

  return {
    client_knowledge: clientKnowledge,
    user_agent: window.navigator.userAgent,
    ga_client_id: getGaClientId(),
    rpcid: rParams.params.rpcid || null,
    ga_session_id: getCookie(GTM.sessionIdCookie),
    cidb: getCookie('_ga'),
    gaexp: getCookie('_gaexp'),
    gcl_au: getCookie('_gcl_au'),
    gcl_aw: getCookie('_gcl_aw'),
    gcl_dc: getCookie('_gcl_dc'),
    fbp: getCookie('_fbp'),
    fbc: getCookie('_fbc'),
    SCOOPR_OPTIMIZE_EXP: getCookie('SCOOPR_OPTIMIZE_EXP'),
    ttclid: getCookie('ttclid'),
    _ttp: getCookie('_ttp'),
    snapchat_uuid_c1: getCookie('uuid_c1'),
    snapchat_sc_cid: rParams.params.ScCid || null,
    page_url: window.location.href,
  };
};
