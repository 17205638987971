import { clearMyScooprStore } from '@/pages/MyScoopr/_store';
import { DOMAIN } from '@/services/Config';
import { removeTokens } from '@/services/tokens';

/** Clears all persistent state */
export const clearStorage = () => {
  localStorage.removeItem(DOMAIN.registerSourceSession);
  removeTokens();
  clearMyScooprStore();
};
