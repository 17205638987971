export const sha256 = async (message: string): Promise<string> => {
  try {
    return native(message);
  } catch {
    return fallback(message);
  }
};

const native = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

const fallback = async (message: string) => {
  const { sha256 } = await import('js-sha256');
  return sha256(message);
};
