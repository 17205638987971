import { SvgIcon } from '@mui/material';

const LogoIcon = ({ ...props }) => (
  <SvgIcon
    viewBox="0 0 109 24"
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="24"
    className={{ color: '#fff' }}
    titleAccess="Scoopr logo"
    {...props}
  >
    <path d="M0.785066 16.5679C0.341753 16.3296 0 15.8175 0 15.1363C0 14.2151 0.71736 13.4663 1.67169 13.4663C2.01344 13.4663 2.31973 13.5677 2.55831 13.7046C4.29932 14.8641 6.10804 15.4439 7.74587 15.4439C9.52074 15.4439 10.5444 14.6934 10.5444 13.5001V13.4308C10.5444 12.033 8.63411 11.5563 6.5175 10.9073C3.85601 10.1585 0.886625 9.06823 0.886625 5.62517V5.55592C0.886625 2.14667 3.71899 0.067627 7.30256 0.067627C9.21283 0.067627 11.2263 0.613557 12.9673 1.5331C13.5476 1.83908 13.9571 2.38501 13.9571 3.10165C13.9571 4.05662 13.2059 4.77165 12.2515 4.77165C11.9098 4.77165 11.7051 4.70401 11.4326 4.56712C9.96566 3.81667 8.46323 3.33999 7.201 3.33999C5.59702 3.33999 4.67493 4.09044 4.67493 5.11305V5.18069C4.67493 6.51089 6.62067 7.05521 8.73567 7.73803C11.3633 8.55612 14.3327 9.74944 14.3327 12.988V13.0556C14.3327 16.8401 11.3972 18.7146 7.64432 18.7146C5.35682 18.7146 2.86622 17.9996 0.785066 16.5679Z" />
    <path d="M16.4412 9.47729V9.40965C16.4412 4.29497 20.3988 0 25.8249 0C28.6573 0 30.603 0.919546 32.1377 2.24975C32.4101 2.48809 32.7858 3.0002 32.7858 3.68141C32.7858 4.73784 31.933 5.55593 30.8755 5.55593C30.3628 5.55593 29.9195 5.35141 29.6471 5.14688C28.5896 4.26116 27.4289 3.61216 25.7911 3.61216C22.7878 3.61216 20.5697 6.20331 20.5697 9.33879V9.40643C20.5697 12.6112 22.7878 15.1685 25.962 15.1685C27.5998 15.1685 28.862 14.5211 29.9889 13.5661C30.2274 13.3616 30.6369 13.1233 31.1157 13.1233C32.1055 13.1233 32.8905 13.9414 32.8905 14.9301C32.8905 15.4761 32.6858 15.9189 32.3102 16.2249C30.7062 17.7596 28.7605 18.7823 25.7588 18.7823C20.3988 18.7839 16.4412 14.5904 16.4412 9.47729Z" />
    <path d="M34.6977 9.47726V9.40963C34.6977 4.26274 38.7923 0.00158691 44.32 0.00158691C49.8477 0.00158691 53.9084 4.19511 53.9084 9.34199V9.40963C53.9084 14.5227 49.8138 18.7838 44.2507 18.7838C38.7584 18.7838 34.6977 14.5903 34.6977 9.47726ZM49.78 9.47726V9.40963C49.78 6.23872 47.4941 3.61375 44.2523 3.61375C40.9428 3.61375 38.8261 6.2049 38.8261 9.34038V9.40802C38.8261 12.5451 41.112 15.1701 44.32 15.1701C47.665 15.1701 49.78 12.5789 49.78 9.47726Z" />
    <path d="M56 9.47726V9.40963C56 4.26274 60.0946 0.00158691 65.6239 0.00158691C71.1532 0.00158691 75.2123 4.19511 75.2123 9.34199V9.40963C75.2123 14.5227 71.1178 18.7838 65.5546 18.7838C60.0607 18.7838 56 14.5903 56 9.47726ZM71.0839 9.47726V9.40963C71.0839 6.23872 68.798 3.61375 65.5562 3.61375C62.2467 3.61375 60.1301 6.2049 60.1301 9.34038V9.40802C60.1301 12.5451 62.4159 15.1701 65.6239 15.1701C68.9673 15.1701 71.0839 12.5789 71.0839 9.47726Z" />
    <path d="M77.6893 9.99262C77.6893 5.22901 80.0928 0 87.3148 0C91.5464 0 95.6749 3.34 95.6749 9.3404V9.40804C95.6749 15.373 91.5803 18.7484 87.3148 18.7484C84.3116 18.7484 83.0783 17.94 81.8177 16.7048V21.9516C81.8177 23.1111 80.8956 23.9968 79.7366 23.9968C78.6097 23.9968 77.6893 23.1111 77.6893 21.9516V9.99262V9.99262ZM91.9623 9.40804V9.3404C91.9623 5.86352 89.6071 3.57995 86.8102 3.57995C84.0117 3.57995 81.555 5.89734 81.555 9.3404V9.40804C81.555 12.8511 84.0117 15.1701 86.8102 15.1701C89.641 15.1701 91.9623 12.9542 91.9623 9.40804Z" />
    <path d="M98.2848 10.8171C98.2848 4.10651 102.919 0.136841 106.953 0.136841C108.249 0.136841 109 0.98875 109 2.18207C109 3.27232 108.283 3.98895 107.362 4.15966C104.428 4.67016 102.415 6.91991 102.415 11.1811V16.4987C102.415 17.6243 101.493 18.5439 100.334 18.5439C99.2069 18.5439 98.2864 17.6581 98.2864 16.4987V10.8171H98.2848Z" />
  </SvgIcon>
);

export default LogoIcon;
