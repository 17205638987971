import { SvgIcon } from '@mui/material';

const HomeIcon = ({ ...props }) => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1162_6398)">
      <path
        d="M19.8753 8.70977L14.5423 4.56177C14.0743 4.19768 13.4982 4 12.9053 4C12.3123 4 11.7363 4.19768 11.2683 4.56177L5.93427 8.70977C5.61372 8.95905 5.35437 9.2783 5.17603 9.64312C4.99769 10.0079 4.90508 10.4087 4.90527 10.8148V18.0148C4.90527 18.5452 5.11599 19.0539 5.49106 19.429C5.86613 19.8041 6.37484 20.0148 6.90527 20.0148H18.9053C19.4357 20.0148 19.9444 19.8041 20.3195 19.429C20.6946 19.0539 20.9053 18.5452 20.9053 18.0148V10.8148C20.9053 9.99177 20.5253 9.21477 19.8753 8.70977Z"
        stroke="#031839"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.875 15C14.665 16.333 11.083 16.333 8.875 15"
        stroke="#031839"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1162_6398">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.875)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default HomeIcon;
