import { addDays, lightFormat } from 'date-fns';

import routes from '@/assets/constants/routes-no';
import { apiClient } from '@/lib/api/apiClient';
import { withSearchParams } from '@/lib/utils';
import { API } from '@/services/Config';

export const defaultApplicationsUrl = withSearchParams(
  routes.AGENT_PORTAL_APPLICATIONS,
  {
    sort: 'created_at',
    desc: 'true',
    f_created_at: JSON.stringify([
      lightFormat(addDays(new Date(), -14), 'yyyy-MM-dd'),
      null,
    ]),
  },
);

export const automadaClient = apiClient.extend({
  prefixUrl: API.url.replace('api', 'automada'),
  timeout: false,
  credentials: 'include',
});
