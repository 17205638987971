const customTypographyStyles = {
  subtitle1: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.5rem', // 24px
    },
  },
  subtitle2: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.375rem', // 22px
    },
  },

  p1: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.25rem', // 20px
    },
  },
  p2: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.125rem', // 18px
    },
  },
  p3: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1rem', // 16px
    },
  },
  p4: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.75rem', // 12px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '0.875rem', // 14px
    },
  },
};

export const customTypography = {
  subtitle1Bold: {
    ...customTypographyStyles.subtitle1,
    fontWeight: 600,
    '@media (min-width:600px)': {
      ...customTypographyStyles.subtitle1,
      fontWeight: 700,
    },
  },
  subtitle2Bold: {
    ...customTypographyStyles.subtitle2,
    fontWeight: 600,
    '@media (min-width:600px)': {
      ...customTypographyStyles.subtitle2,
      fontWeight: 700,
    },
  },

  p1: {
    color: '#031839',
    ...customTypographyStyles.p1,
    fontWeight: 400,
  },
  p1SemiBold: {
    ...customTypographyStyles.p1,
    fontWeight: 600,
  },

  p2: {
    ...customTypographyStyles.p2,
    fontWeight: 400,
  },
  p2SemiBold: {
    ...customTypographyStyles.p2,
    fontWeight: 600,
  },

  p3: {
    ...customTypographyStyles.p3,
    fontWeight: 400,
  },
  p3SemiBold: {
    ...customTypographyStyles.p3,
    fontWeight: 600,
  },
  p3ExtraBold: {
    ...customTypographyStyles.p3,
    fontWeight: 800,
  },

  p4: {
    ...customTypographyStyles.p4,
    fontWeight: 400,
  },
  p4SemiBold: {
    ...customTypographyStyles.p4,
    fontWeight: 600,
  },

  button1: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.25rem', // 20px
    },
  },
  button2: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '1rem', // 16px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1.125rem', // 18px
    },
  },
  button3: {
    fontFamily: "'Nunito', sans-serif",
    fontWeight: 600,
    fontSize: '0.875rem', // 14px
    lineHeight: 1.4,
    '@media (min-width:600px)': {
      fontSize: '1rem', // 16px
    },
  },
};

export const newTypography = {
  display1n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '2.438rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '3.812rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '5.125rem',
    },
  },
  h1n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.562rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.812rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '2.438rem',
    },
  },
  h2n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.375rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.562rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.812rem',
    },
  },
  h3n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.188rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.375rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.562rem',
    },
  },
  h4n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.188rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.188rem',
    },
  },
  h5n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1rem',
    },
  },
  body1n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1.188rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '0%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.375rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.562rem',
    },
  },
  body2n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.188rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.375rem',
    },
  },
  body3n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.188rem',
    },
  },
  body4n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '0.875rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1rem',
    },
  },
  caption1n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '0.75rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '0.875rem',
    },
  },
  button1n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1.188rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.375rem',
    },
  },
  button2n: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '140%',
    letterSpacing: '1%',
    '@media (min-width:600px) and (max-width:1199px)': {
      fontSize: '1rem',
    },
    '@media (min-width:1200px)': {
      fontSize: '1.188rem',
    },
  },
};

export const typography = {
  fontFamily: "'Nunito', sans-serif",
  fontWeightLight: undefined,
  fontWeightRegular: 400,
  fontWeightMedium: undefined,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  color: '#031839',
  h1: {
    fontWeight: 700,
    fontSize: '1.625rem', // 26px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '3.5rem', // 56px
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.375rem', // 22px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '2.25rem', // 36px
    },
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.125rem', // 18px
    lineHeight: 1.4,
    color: '#031839',
    '@media (min-width:600px)': {
      fontSize: '1.75rem', // 28px
    },
  },
  subtitle1: {
    fontWeight: 400,
    ...customTypographyStyles.subtitle1,
  },
  subtitle2: {
    fontWeight: 400,
    ...customTypographyStyles.subtitle2,
  },
  ...customTypography,
  // remove Mui styling
  h4: null,
  h5: null,
  h6: null,
  caption: {
    fontSize: '0.875rem',
  },
  overline: null,
  body1: null,
  body2: null,
  button: null,
  ...newTypography,
};

type CustomKeysOld = keyof typeof customTypography;
export type NewTypographyVariants = keyof typeof newTypography;

export type NewTypographyType = typeof newTypography;

type CustomVariants = {
  [k in CustomKeysOld]: true;
} & {
  [k in NewTypographyVariants]: true;
};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends CustomVariants {
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    subtitle1: true;
    subtitle2: true;
    h5: false;
    h6: false;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants extends NewTypographyType {}

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions extends NewTypographyType {}
}
