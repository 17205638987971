import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import { SIZES } from '../../assets/constants/constants';
import { LANG } from '../../services/Config';
import Typography from '../Typography';

const useStyles = makeStyles()(({ breakpoints, palette }, { inline }) => ({
  loaderContainer: {
    minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
    [breakpoints.up(SIZES.navigationChange)]: {
      minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
    },
  },
  flowDirection: {
    flexDirection: 'column',
    margin: 20,
    minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
    [breakpoints.up(SIZES.navigationChange)]: {
      minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
    },
  },
  loaderText: {
    display: 'block',
    marginTop: 10,
    marginBottom: 10,
    color: palette.neutral.medium,
  },
}));

const Loader = ({ inline, colored, text, size, customColor }) => {
  const { classes } = useStyles({ inline });
  const { appStrings } = LANG;

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flex={1}
      mb={inline ? 0 : 8}
      className={text ? classes.flowDirection : classes.loaderContainer}
    >
      {text ? (
        <Typography variant="p3" color="primary" className={classes.loaderText}>
          {text}
        </Typography>
      ) : null}
      <CircularProgress
        size={size}
        color={colored ? 'secondary' : 'primary'}
        aria-label={appStrings.LOADING}
        style={customColor && { color: customColor }}
      />
    </Box>
  );
};

Loader.propTypes = {
  inline: PropTypes.bool,
  colored: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.any,
  customColor: PropTypes.string,
};

Loader.defaultProps = {
  inline: false,
  colored: false,
  text: null,
  size: 30,
  customColor: null,
};

export default Loader;
