import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import useBrainSession from '../../services/BrainSession/useBrainSession';
import useTracking from '../../services/Tracking/useTracking';
import Typography from '../Typography';

const useStyles = makeStyles()(() => ({
  buttonLabel: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '0 !important',
  },
}));

const ActionButton = ({
  children,
  label,
  step,
  flow,
  clientKnowledge,
  color,
  gaEvent,
  gaParameters,
  className,
  size,
  onClick,
  labelClassName,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { toggleChat } = useBrainSession();
  const { addEvent } = useTracking();

  return (
    <Button
      variant="contained"
      size={size || 'large'}
      className={className}
      color={color === 'transparent' ? 'inherit' : color}
      onClick={() => {
        if (gaEvent) {
          addEvent(gaEvent, gaParameters);
        }
        if (onClick) {
          onClick();
          return;
        }
        toggleChat({
          goal: flow,
          step,
          clientKnowledge,
        });
      }}
      {...props}
    >
      <Typography
        component="p"
        variant="button2n"
        className={cx(classes.buttonLabel, labelClassName)}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} /> {children}
      </Typography>
    </Button>
  );
};

ActionButton.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  flow: PropTypes.string,
  step: PropTypes.string,
  clientKnowledge: PropTypes.any,
  color: PropTypes.oneOf(['secondary', 'primary', 'default']),
  gaEvent: PropTypes.string,
  gaParameters: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  labelClassName: PropTypes.string,
};

ActionButton.defaultProps = {
  children: null,
  label: '',
  flow: '',
  step: '',
  clientKnowledge: {},
  color: 'secondary',
  size: 'large',
  gaEvent: null,
  gaParameters: null,
  className: undefined,
  onClick: undefined,
  labelClassName: undefined,
};

export default ActionButton;
