import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Loader from '../components/Loader';
import { LANG } from '../services/Config';

const Jobs = lazy(() => import('./Jobs'));
const ErrorPage = lazy(() => import('./ErrorPage'));
const LiveOffers = lazy(() => import('./LiveOffers'));
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Terms = lazy(() => import('./Terms'));
const TermsRefinancing = lazy(() => import('./TermsRefinancing'));
const TermsMinScoopr = lazy(() => import('./TermsMinScoopr'));
const TermsMortgage = lazy(() => import('./TermsMortgage'));
const Cookies = lazy(() => import('./Cookies'));
const Privacy = lazy(() => import('./Privacy'));
const PrivacyStatement = lazy(() => import('./PrivacyStatement'));
// const PartnerBanks = lazy(() => import('./PartnerBanks'));
// const PartnerBankDetails = lazy(() => import('./PartnerBankDetails'));
const FaqArticle = lazy(() => import('./FaqArticle'));
const NoMatch = lazy(() => import('./NoMatch'));
const SavingsPotential = lazy(() => import('./SavingsPotential'));
const PropertyValue = lazy(() => import('./PropertyValue'));
const DebtInformation = lazy(() => import('./DebtInformation'));
const CreditCheckMyself = lazy(() => import('./CreditCheckMyself'));
const MyDebt = lazy(() => import('./MyDebt'));
const MyEconomy = lazy(() => import('./MyEconomy'));
const InterestRate = lazy(() => import('./InterestRate'));
const RepaymentPeriod = lazy(() => import('./RepaymentPeriod'));
const CreditCardLoan = lazy(() => import('./CreditCardLoan'));
const SmallLoan = lazy(() => import('./SmallLoan'));
const DebtFree = lazy(() => import('./DebtFree'));
const DebtOverview = lazy(() => import('./DebtOverview'));
const CompareLoans = lazy(() => import('./CompareLoans'));
const Microloan = lazy(() => import('./Microloan'));
const VerifiedRefinancing = lazy(() => import('./VerifiedRefinancing'));
const RefinancingLoans = lazy(() => import('./RefinancingLoans'));
const CompareConsumerLoans = lazy(() => import('./CompareConsumerLoans'));
const SaveOrRepayLoan = lazy(() => import('./SaveOrRepayLoan'));
const NominalAndEffectiveInterestRate = lazy(
  () => import('./NominalAndEffectiveInterestRate'),
);

const LoggedOut = lazy(() => import('./LoggedOut'));

const LegacyRoutes = () => {
  const { routes } = LANG;
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path={routes.HOME} element={<Home />} />
        <Route path={routes.ABOUT} element={<About />} />
        <Route path={routes.TERMS_OF_USE} element={<Terms />} />
        <Route
          path={routes.TERMS_OF_USE_MORTGAGE}
          element={<TermsMortgage />}
        />
        <Route
          path={routes.TERMS_OF_USE_REFINANCING}
          element={<TermsRefinancing />}
        />
        <Route
          path={routes.TERMS_OF_USE_MIN_SCOOPR}
          element={<TermsMinScoopr />}
        />
        <Route path={routes.JOBS} element={<Jobs />} />
        <Route path={routes.COOKIES} element={<Cookies />} />
        <Route path={routes.PROPERTY_VALUE} element={<PropertyValue />} />
        <Route path={routes.INTEREST_RATE} element={<InterestRate />} />
        <Route path={routes.MICROLOAN} element={<Microloan />} />
        <Route
          path={routes.NOMINAL_AND_EFFECTIVE_INTEREST_RATE}
          element={<NominalAndEffectiveInterestRate />}
        />
        <Route path={routes.REFINANCING_LOANS} element={<RefinancingLoans />} />
        <Route path={routes.REPAYMENT_PERIOD} element={<RepaymentPeriod />} />
        <Route path={routes.ERROR_PAGE} element={<ErrorPage />} />
        <Route path={routes.DEBT_FREE} element={<DebtFree />} />
        <Route path={routes.DEBT_OVERVIEW} element={<DebtOverview />} />
        <Route path={routes.COMPARE_LOANS} element={<CompareLoans />} />
        <Route
          path={routes.COMPARE_CONSUMER_LOANS}
          element={<CompareConsumerLoans />}
        />
        <Route path={routes.SAVE_OR_REPAY_LOAN} element={<SaveOrRepayLoan />} />
        <Route path={routes.CREDIT_CARD_LOAN} element={<CreditCardLoan />} />
        <Route path={routes.SMALL_LOAN} element={<SmallLoan />} />
        <Route path={routes.DEBT_INFORMATION} element={<DebtInformation />} />
        <Route path={routes.MY_ECONOMY} element={<MyEconomy />} />
        <Route
          path={routes.CREDIT_CHECK_MYSELF_CHAT}
          element={<CreditCheckMyself />}
        />
        <Route
          path={routes.CREDIT_CHECK_MYSELF}
          element={<CreditCheckMyself />}
        />
        <Route path={routes.PRIVACY} element={<Privacy />} />
        <Route path={routes.PRIVACY_STATEMENT} element={<PrivacyStatement />} />
        <Route path={routes.MY_DEBT} element={<MyDebt />} />
        {/* <Route
          path={routes.PARTNER_BANK_DETAILS}
          element={<PartnerBankDetails />}
        /> */}
        {/* <Route path={routes.PARTNER_BANKS} element={<PartnerBanks />} /> */}
        <Route path={routes.SAVINGS_POTENTIAL} element={<SavingsPotential />} />
        <Route path={routes.LIVE_OFFERS} element={<LiveOffers />} />
        <Route
          path={routes.VERIFIED_REFINANCING}
          element={<VerifiedRefinancing />}
        />
        <Route path={routes.LOGGED_OUT} element={<LoggedOut />} />
        <Route path={routes.PRODUCTS_FAQ_ARTICLE} element={<FaqArticle />} />
        <Route
          path={routes.CHEAPEST_LOAN}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.UNSECURED_LOAN}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.BEST_CONSUMER_LOAN}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.LOAN_CALCULATOR}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.CONSUMER_LOAN_WITH_PAYMENT_REMARK}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.LOAN_MONEY}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.ABOUT_LOAN}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route
          path={routes.PRIVATE_LOAN}
          element={<Navigate to={routes.PRODUCTS_REFINANCING} replace />}
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export default LegacyRoutes;
