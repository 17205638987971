import { Box, Typography } from '@mui/material';
import { redirect, type RouteObject } from 'react-router-dom';

import { AgentErrorBoundary } from './components/AgentErrorBoundary';
import { defaultApplicationsUrl } from './lib/misc';

import routes from '@/assets/constants/routes-no';
import { requireAgentAuth } from '@/lib/router-utils';

export const agentPortalRoute: RouteObject = {
  loader: ({ request }) => requireAgentAuth(request),
  lazy: () =>
    import('./components/AgentPortalLayout').then((m) => ({
      Component: m.AgentPortalLayout,
    })),
  errorElement: <AgentErrorBoundary />,
  children: [
    {
      errorElement: <AgentErrorBoundary />,
      children: [
        {
          path: routes.AGENT_PORTAL_LOGIN,
          lazy: () => import('./pages/Login'),
        },
        {
          path: routes.AGENT_PORTAL_APPLICATIONS,
          lazy: () => import('./pages/Applications'),
        },
        {
          path: routes.AGENT_PORTAL_APPLICATION_DETAILS,
          lazy: () => import('./pages/ApplicationDetails'),
        },
        {
          path: routes.AGENT_PORTAL,
          loader: () => redirect(defaultApplicationsUrl),
        },
        {
          path: routes.AGENT_PORTAL_DEBT_REGISTER,
          lazy: () => import('./pages/DebtRegister'),
        },
        {
          path: routes.AGENT_PORTAL_DEBT_REGISTER_DETAILS,
          lazy: () => import('./pages/DebtRegisterDetails'),
        },
        {
          path: routes.AGENT_PORTAL_EXPIRED_CASES,
          lazy: () => import('./pages/ExpiredCases'),
        },
        {
          path: `${routes.AGENT_PORTAL}/*`,
          loader: ({ request }) => requireAgentAuth(request),
          Component: () => (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <Typography variant="h1n">This page does not exist</Typography>
            </Box>
          ),
        },
      ],
    },
  ],
};
