import type { RouteObject } from 'react-router-dom';

import routes from '@/assets/constants/routes-no';
import { getDecember2023ConfigQuery } from '@/lib/api/queries';
import { simpleRace, smartLazy } from '@/lib/router-utils';
import { queryClient } from '@/queryClient';

export const december2023Routes: RouteObject[] = [
  {
    path: routes.DECEMBER_2023_COMPETITION,
    loader: () =>
      simpleRace([queryClient.prefetchQuery(getDecember2023ConfigQuery())]),
    lazy: () => smartLazy(import('./LandingPage')),
  },
  {
    path: routes.DECEMBER_2023_COMPETITION_TERMS,
    lazy: () => smartLazy(import('./Terms')),
  },
];
