import { jwtDecode } from 'jwt-decode';

import { store } from './_store';
import type { AccessTokenPayload } from './types';

export const getTokens = () => store.getState().tokens;
export const saveTokens = store.getState().setTokens;
export const removeTokens = store.getState().removeTokens;

export const isTokenExpired = (token: string): boolean => {
  const parsedToken = jwtDecode(token);

  // `exp` is expressed in seconds, while `Date.now()` returns milliseconds
  const now = Math.ceil(Date.now() / 1000);

  return !!parsedToken.exp && parsedToken.exp < now;
};

export const decodeAccessToken = (token: string) =>
  jwtDecode<AccessTokenPayload>(token);
