export const normalizePhoneNumberToE164 = (phoneNumber: string): string => {
  // Important: If you modify this function make sure to also modify its equivalent in the pubsub subscriber

  phoneNumber = phoneNumber
    .replace(/\s/g, '')
    .replace(/-/g, '')
    .replace(/^0+/, '+'); // Let's replace all leading zeros with +

  // Extra fallback for Norwegian numbers
  if (phoneNumber.length === 8) {
    phoneNumber = `+47${phoneNumber}`;
  }

  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+${phoneNumber}`;
  }

  return phoneNumber;
};

export const normalizePhoneNumberToE164NoPlus = (
  phoneNumber: string,
): string => {
  // Important: If you modify this function make sure to also modify its equivalent in the pubsub subscriber

  return normalizePhoneNumberToE164(phoneNumber).replace(/^\+/, '');
};

export const normalizeEmail = (email: string): string => {
  // Important: If you modify this function make sure to also modify its equivalent in the pubsub subscriber

  return email.trim().toLocaleLowerCase();
};

/**
 * Removes dots `.` from the part before `@` in google emails.
 * Email must be normalized.
 */
export const removeDotsFromGmail = (email: string): string => {
  // Important: If you modify this function make sure to also modify its equivalent in the pubsub subscriber

  let [local, ...rest] = email.split('@');
  const domain = rest.join('@');

  if (['gmail.com', 'googlemail.com'].includes(domain)) {
    local = local.replace(/\./g, '');
  }

  return `${local}@${domain}`;
};
